import { HttpHeader } from '@solidjs/start';
import { Button, Link } from '@troon/ui';
import { Title } from '@solidjs/meta';
import { Content } from '../../components/content';
import { GenericLayout } from '../../layouts/generic';

export default function NotFound() {
	return (
		<GenericLayout>
			<Content>
				<Title>404 not found | Troon</Title>
				<HttpHeader name="status" value="404" />
				<h1 class="my-16 text-6xl font-semibold text-brand">Not Found</h1>

				<div class="flex flex-row">
					<div>
						<Button as={Link} href="/">
							Return home
						</Button>
					</div>
				</div>
			</Content>
		</GenericLayout>
	);
}
